* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
}

.notFoundPage {
  color: rgb(156, 156, 156);
  font-size: 24px;
}

.notFoundPage a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.bannerBack {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d1d1d;
}

.bannerImg {
  background-image: url("img/logo.svg");
  width: 100%;
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  animation: fadeInOut 3s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

body {
  overflow-x: hidden;
}

html,
body,
#root {
  height: 100%;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url("./img/bg.png") no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  transition: 1s;
  animation: show 1s 1;
  animation-fill-mode: forwards;
}

.triangleWrapper {
  min-height: 70vh;
  background: url("./img/tr.png") no-repeat center;
  background-size: contain;
}

.hideTriangleWrapper {
  min-height: 70vh;
}

.leadershipGreyBackground {
  background-color: #232323;
  background-size: contain;
  min-height: 70vh;
}

main {
  flex: 1;
  position: relative;
}

header {
  padding: 48px 97px 0 97px;
}

header ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

header ul div {
  display: flex;
  gap: 50px;
  align-items: center;
}

.logo {
  width: 244px;
  height: 50px;
  background: url("img/logo.svg");
}

header ul a {
  text-decoration: none;
  color: #ffffff85;
}

header ul a:hover {
  color: #fff;
}

.footer {
  height: 30vh;
  margin-top: auto;
  background-color: rgba(23, 21, 22, 1);
  color: rgba(255, 255, 255, 1);
  padding: 48px 100px 54px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer__top h3 {
  font-size: 24px;
}

.footer__top p {
  margin-top: 10px;
  line-height: 30px;
}

.footer__top a {
  color: white;
  text-decoration: none;
}

.footer__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.footer__docs {
  display: flex;
  gap: 34px;
}

.footer__bottom a {
  text-decoration: none;
  color: #fff;
}

.marginLeft90 {
  margin-left: 50px;
}

.page {
  text-align: center;
  margin-top: 7%;
}

.page h1 {
  font-size: 42px;
  color: #fff;
  font-weight: 100;
}

.page p {
  font-size: 26px;
  color: #fff;
  max-width: 70%;
  margin: 0 auto;
}

.line {
  width: 1px;
  height: 87px;
  background-color: #797979;
  margin: 10px auto;
  transition: 1s;
  animation: show 1s 1;
  animation-fill-mode: forwards;
}

.leadership {
  background-color: white;
  padding: 55px 45px;
  line-height: 30px;
  color: #565656;
}

.leadership__wrapper {
  max-width: 1366px;
  margin: 0 auto;
}

.leadership strong {
  font-weight: 700;
}

.leadership__history {
  margin-top: 30px;
  display: flex;
  gap: 125px;
}

.leadership__year {
  min-width: 190px;
}

.leadership__year h3 {
  color: #6d6359;
  font-size: 28px;
  font-weight: 700;
  position: relative;
}

.leadership__year h3:after {
  content: "";
  background-color: #30454f;
  position: absolute;
  left: 80px;
  top: 15px;
  width: 220px;
  height: 1px;
  display: block;
}

.leadership__year:last-child h3::after {
  display: none;
}

.leadership__year p {
  margin-top: 10px;
}

.leadership__text {
  margin-top: 25px;
}

.preloadImg1 {
  background: url("./img/background2.png") no-repeat;
}

.preloadImg2 {
  background: url("./img/background3.png") no-repeat;
}

.privateClientsPage {
  background: url("./img/background3.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

.corporationsPage {
  background: url("./img/background2.png") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: left;
}

@keyframes show {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tabs__header {
  background-color: rgba(23, 21, 22, 1);
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 75px;
}

.tabs__content {
  background-color: #fff;
  min-height: 355px;
  padding-top: 50px;
}

.tab {
  border: none;
  background-color: rgba(23, 21, 22, 1);
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.tab:hover {
  border-bottom: 1px solid #fff;
  margin-top: 1px;
  cursor: pointer;
}

.tab--active {
  border-bottom: 1px solid #fff;
  margin-top: 1px;
  color: #aeaeae;
}

.tabs_wrapper {
  max-width: 1060px;
  margin: 0 auto;
}

.tabs__ul {
  color: #565656;
  font-size: 18px;
  list-style: none;
}

.tabs__ul li {
  margin-bottom: 25px;
  line-height: 25px;
  position: relative;
}

.tabs__ul li::before {
  content: "⬥";
  position: absolute;
  left: -13px;
  color: #897b6f;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.burger-menu {
  display: none;
}

.mobile-menu {
  display: none;
  z-index: 2;
  transition: 1s;
}

.mobile-menu ul {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1d1d1d;
  color: #fff;
  width: 100%;
  height: 100vh;
  z-index: 1;
  padding-top: 100px;
}

.mobile-menu li {
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}

.mobile-menu label {
  cursor: pointer;
  font-size: 20px;
  color: rgba(137, 123, 111, 1);
}

.mobile-menu input[type="checkbox"] {
  display: none;
}

.mobile-menu input[type="checkbox"]:checked ~ ul {
  display: block;
}

.mobile-menu input[type="checkbox"]:checked ~ label {
  position: fixed;
  z-index: 2;
  visibility: hidden;
}

.mobile-menu input[type="checkbox"]:checked ~ label::after {
  content: "×";
  font-size: 40px;
  visibility: visible;
  position: fixed;
  top: 40px;
  right: 40px;
}

.aboutUsMobile,
.ourMissionMobile {
  display: none;
  padding: 90px 136px;
  color: #fff;
}

.aboutUsMobile {
  background-color: rgba(59, 55, 52, 1);
}

.ourMissionMobile {
  background-color: rgba(35, 35, 35, 1);
}

.aboutUsMobile h2,
.ourMissionMobile h2 {
  font-size: 24px;
}

.aboutUsMobile p,
.ourMissionMobile p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 23px;
}

.photoLeadership {
  position: relative;
}

.photoLeadership::after {
  content: "";
  background-image: url("./img/photo.png");
  width: 400px;
  height: 490px;
  position: absolute;
  background-size: cover;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

@media screen and (max-width: 1380px) {
  header {
    padding: 24px 50px 0 50px;
  }

  .page {
    margin-top: 10%;
  }
}

@media screen and (max-width: 1250px) {
  .pc-btn {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
  .mobile-menu ul {
    display: none;
  }
  .aboutUsMobile,
  .ourMissionMobile {
    display: block;
  }
  header {
    padding: 24px 136px 0 136px;
  }
  .page {
    padding: 0 136px 100px 136px;
  }
  .tabs__header {
    background-color: #232323;
    flex-direction: column;
    align-items: start;
    height: auto;
    padding: 45px 0;
    gap: 30px;
  }
  .tab {
    background-color: #232323;
    margin-left: 40%;
    position: relative;
  }
  .tab--active {
    border: none !important;
  }
  .tab--active::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #fff;
    left: -50px;
    top: 10px;
  }
  .tabs__content {
    padding: 50px 156px;
    min-height: auto;
  }
  .tab:hover {
    border: none;
    margin-top: 0px;
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  .page {
    margin-top: 10%;
  }
}

@media screen and (max-width: 988px) {
  .photoLeadership::after {
    width: 280px;
    height: 350px;
  }
  .leadership__history {
    flex-direction: column;
    align-items: center;
    gap: 84px;
  }
  .leadership__year h3::after {
    display: none;
  }
  .leadership__year {
    position: relative;
    text-align: center;
  }
  .leadership__year::after {
    content: "";
    background-color: #30454f;
    position: absolute;
    left: 100px;
    top: 110px;
    width: 1px;
    height: 59px;
    display: block;
  }
  .leadership__year:last-child::after {
    display: none;
  }
  .page h1 {
    font-size: 36px;
  }
  .page p {
    font-size: 16px;
  }
  .tabs__ul {
    font-size: 16px;
  }
  .footer {
    height: auto;
    padding: 20px 10px;
  }
  .footer__top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__top p {
    font-size: 20px;
    text-align: center;
  }
  .footer__bottom {
    flex-direction: column-reverse;
    align-items: center;
    font-size: 14px;
  }
  .footer__docs {
    justify-content: center;
    margin-bottom: 20px;
  }
  .triangleWrapper {
    background-size: 400px 400px;
    min-height: 55vh;
  }
}

@media screen and (max-width: 780px) {
  .logo {
    width: 122px;
    height: 25px;
    background-size: cover;
  }
  .page {
    padding-bottom: 100px;
    margin-top: 15%;
  }
  .tab {
    margin-left: 35%;
  }
}

@media screen and (max-width: 600px) {
  .page {
    margin-top: 20%;
  }
  .page p {
    max-width: 95%;
  }
  .tab {
    margin-left: 25%;
  }
}

@media screen and (max-width: 592px) {
  .aboutUsMobile h2,
  .ourMissionMobile h2 {
    max-width: 320px;
    display: block;
    margin: 0 auto;
  }

  .aboutUsMobile p,
  .ourMissionMobile p {
    max-width: 320px;
    display: block;
    margin: 0 auto;
    margin-top: 23px;
  }
  header {
    padding: 24px 0 0 0;
  }
  header ul {
    margin: 0 auto;
    padding: 0 10%;
  }
  .page {
    padding: 0 0 100px 0;
    max-width: 320px;
    margin: 0 auto;
    margin-top: 23%;
  }
  .tabs__content {
    padding: 50px 0;
  }
  .tabs__ul {
    max-width: 320px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 560px) {
  .aboutUsMobile,
  .ourMissionMobile {
    padding: 90px 0;
  }
}

@media screen and (max-width: 340px) {
  header ul {
    padding: 5%;
  }
  .page {
    max-width: 270px;
  }
  .aboutUsMobile p,
  .ourMissionMobile p,
  .aboutUsMobile h2,
  .ourMissionMobile h2 {
    max-width: 250px;
  }
  .footer__bottom {
    font-size: 11px;
  }
  .footer__top h3 {
    font-size: 20px;
  }
  .tabs__ul {
    max-width: 270px;
  }
}
